import { ColumnFlex, FlexCentered, RowFlex } from '@/themes/genericStyles'
import styled, { css } from 'styled-components'

const minPlayerHeight = '66px'
const minPlayerHeightNoiceBook = '76px'
export const Container = styled.div`
    width: 100vw;
    height: ${(props) =>
        props.isNoicebook ? minPlayerHeightNoiceBook : minPlayerHeight};
    background: #1f1f1f;
    ${ColumnFlex}
    align-items: center;
    justify-content: space-between;
    position: fixed;
    padding: 8px 16px;
    bottom: ${(props) => (props.showPlayer ? '60px' : '-100px')};
    z-index: 10;

    @media (min-width: 991px) {
        padding: 10px 24px;
        z-index: 1000;
        transform: ${(props) =>
            !props.showFullScreen
                ? 'translateY(0)'
                : props.mouseMoved
                ? 'translateY(0)'
                : 'translateY(100%)'};
        bottom: ${(props) => (props.showPlayer ? '0' : '-100px')};
    }

    @media (max-width: 990px) {
        border-radius: 8px;
    }

    transition: transform 0.1s ease-in-out;
`

export const PlayerInformation = styled.div`
    ${RowFlex}
    height: 100%;
    align-items: center;
    justify-content: space-around;
    gap: 16px;
    width: 100%;
`

export const ContentImage = styled.img`
    width: 40px;
    /* height: 40px; */
    height: auto;
    border-radius: 4px;
    margin-right: 1vh;
`

export const ContentTitleAndCatalogueNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
    width: 80%;
    height: 100%;

    .title_wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        max-width: 400px;
        margin-right: 20px;

        @media (max-width: 1200px) {
            max-width: 250px;
        }

        @media (max-width: 1100px) {
            max-width: 200px;
        }

        @media (max-width: 400px) {
            max-width: 190px;
        }
    }
`

export const Title = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    width: inherit;
    color: #fafafa;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width) {
        font-size: 11px;
    }
`

export const CatalogueTitle = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #fafafa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    cursor: pointer;

    @media (max-width) {
        font-size: 11px;
    }
`

export const PlayIcon = styled.img`
    min-width: 32px;
    height: 32px;
    cursor: pointer;
`

export const Slider = styled.input`
    width: 100%;

    @media (max-width: 990px) {
        display: none;
    }
`
export const MobileSliderContainer = styled.div`
    width: 100%;
    height: 2px;
    display: flex;
    margin-top: 6px;
    border-radius: 4px;
    overflow: hidden;

    @media (min-width: 990px) {
        display: none;
    }
`

export const MobileSlider = styled.input`
    width: 100%;
`

export const FullScreenContainer = styled.div`
    ${ColumnFlex}

    transform: ${(props) =>
        props.toggleScreen ? 'translateY(0%)' : 'translateY(150%)'};

    transition: transform 0.5s ease-in-out;

    z-index: 999;

    align-items: center;
    justify-content: space-between;
    
    width: calc(100% - 225px);
    height: calc(100% - ${minPlayerHeight});
    right: 0px;

    height: ${(props) =>
        props.showFullScreen ? '100%' : `calc(100% - ${minPlayerHeight})`}; 
  

    @media (max-width: 990px) {
        width: 100%;
        height: 100%;  
    }

    position: fixed;
    @media (min-width: 992px) {
        // width: 698px;
        background-size: cover;
    }
    &:before {
        ${ColumnFlex}
        ${FlexCentered}
        content: '';

        position: absolute;
        left: 0;
        right: 0;
        z-index: 9;
        // display: block;
        background: inherit;
        background-size: cover;
        border-bottom: 1px solid #202020;
        background-position: center center;
        @media (min-width: 760px) {
            background-position: top center;
            background-size: cover;
        }
        width: 100%;
        height: 100%;
        -webkit-filter: blur(20px);
        -moz-filter: blur(20px);
        -o-filter: blur(20px);
        -ms-filter: blur(20px);
        backdrop-filter: blur(20px);
`

export const ThumbnailContainer = styled.div`
    ${ColumnFlex}
    ${FlexCentered}
    z-index: 1;
    width: 100%;
    flex: 2;
    align-self: center;
    text-align: center;
    margin-top: 20px;
    @media (min-width: 414px) {
        margin-top: unset;
    }

    .vip-badge {
        border-bottom-left-radius: 8px;
    }
`

export const ThumbnailImage = styled.img`
    border-radius: 8px;
    /* width: ${({ isAudiobook }) => (isAudiobook ? '181px' : '200px')};
    height: ${({ isAudiobook }) => (isAudiobook ? '241px' : '200px')}; */
    width: 300px;
    height: auto;

    display: ${(props) => (props.isVideo ? 'none' : 'block')};

    @media (min-width: 460px) {
        width: 300px;
    }

    @media (min-width: 990px) {
        width: 400px;
    }
`

export const PlayingContentInformation = styled.div`
    ${ColumnFlex}
    align-items: flex-start;
    justify-content: center;
    padding: 0 24px;
    margin-bottom: 20px;
    gap: 10px;

    @media (min-width: 992px) {
        display: none;
    }
`

export const ExitFullScreenButton = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
`

export const CatalogType = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #fad810;
    margin-top: 20px;
    ${({ isRadio }) =>
        isRadio &&
        css`
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 140%;
            color: #fafafa;
        `}
`

export const EpisodeTitle = styled.div`
    color: #f0f0f0;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;

    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    display: flex;
    gap: 2px;
    justify-content: ${(props) => (props.isRadio ? 'center' : 'left')};
    align-items: center;
`

export const CatalogTitleAndFollowContainer = styled.div`
    ${RowFlex}
    align-items: center;
    justify-content: ${(props) => (props.isRadio ? 'center' : 'flex-start')};
    width: 100%;
    gap: 10px;

    span {
        cursor: pointer;
        text-align: center;
        color: #b9b9b9;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.1px;
    }
`

export const CatalogTitleText = styled.div`
    color: #fafafa;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.1px;

    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
`

export const FollowButtonContainer = styled.button`
    display: flex;
    max-width: 120px;
    height: 28px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 14px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    opacity: ${(props) => (props.subscribed ? '0.5' : '1')};
`

export const FollowIcon = styled.img`
    width: 14px;
    height: 14px;
    margin-right: 2px;
`

export const FollowText = styled.div`
    color: #fafafa;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.5px;
    letter-spacing: 0.1px;
`

export const ListensInformation = styled.div`
    ${RowFlex}
    ${FlexCentered}
    margin-top: 3%
`

export const ListensIcon = styled.img`
    width: 14px;
    height: 14px;
    margin-right: 5px;
`

export const ListensText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 70%;
    color: rgba(255, 255, 255, 0.7);
`

export const PlayerContainer = styled.div`
    ${ColumnFlex}
    justify-content: center;
    align-items: space-between;
    z-index: 2;
    padding: 0px 24px;
    padding-bottom: 20px;

    @media (min-width: 990px) {
        display: none;
    }
`

export const DurationContainer = styled.div`
    width: 100%;
    ${RowFlex}
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`

export const DurationText = styled.div`
    color: #fafafa;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.048px;
`

export const PlayerSlider = styled.input`
    width: 100%;
`

export const SoundSliderContainer = styled.div`
    min-width: 150px;
    display: none;
    gap: 10px;

    img {
        width: 30px;
        height: 30px;
    }

    @media (min-width: 700px) {
        display: flex;
    }
`

export const ControlsContainer = styled.div`
    width: 100%;
    ${RowFlex}
    align-items: center;
    justify-content: ${({ isRadio, showingAd }) =>
        isRadio || showingAd ? 'center' : 'space-between'};
    margin-top: 20px;
    min-height: 50px;
`

export const ControlsIcon = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: ${({ isRadio }) => (isRadio ? '44.92%' : null)};
    @media (min-width: 992px) {
        margin-left: ${({ isRadio }) => (isRadio ? '22.92%' : null)};
    }
`

export const ControlsPlayIcon = styled.img`
    width: 50px;
    height: 50px;
    cursor: pointer;
    align-items: center;
    position: ${({ isRadio }) => (isRadio ? 'absolute' : 'unset')};
`

export const HeaderContainer = styled.div`
    width: 100%;
    justify-content: ${({ isLiveroom, showingAd }) =>
        isLiveroom || showingAd ? 'flex-start' : 'center'};
    align-items: center;
    ${RowFlex}

    position: relative;
    gap: 16px;
`

export const RadioTitle = styled.div`
    color: #f0f0f0;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
`

export const CloseIcon = styled.img`
    cursor: pointer;
    margin-right: 1vh;
`
export const LiveroomHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 85%;
`
export const LiveroomTitle = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0125em;
    text-transform: capitalize;

    color: #ffffff;
`
export const InfoOutline = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`

export const LiveHeaderParticipantCountAndText = styled.div`
    // width: 52px;
    height: 17px;
    gap: 5px;
    /* Body/Body 4/Regular */

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */

    display: flex;
    align-items: center;
    letter-spacing: 0.004em;

    /* White/500 */

    color: #d6d6d6;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

//liveroom container//

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-top: 16px;

    width: 100%;
    cursor: pointer;
`

export const Button = styled.a`
    display: inline-block;
    padding: 11.5px 16px;

    width: auto;
    cursor: pointer;

    /* Candle Light/500 */
    background: #fad810;
    border-radius: 100px;
    border: none;
`

export const ButtonText = styled.span`
    /* Button/Bold/Upper Case */

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */

    letter-spacing: 0.2em;
    text-transform: uppercase;

    /* Black/900 */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

//mini player play icon container

export const PlayIconContainer = styled.div`
    ${RowFlex}
    justify-content: flex-end;
    width: 32px;

    @media (min-width: 700px) {
        display: none;
    }
`
export const PlayPauseContainer = styled.div`
    display: none;
    align-items: center;
    gap: 10px;

    @media (min-width: 700px) {
        display: flex;
    }

    span {
        width: max-content;
        color: #b9b9b9;
        text-align: right;

        font-size: 11px;
        font-weight: 400;
        line-height: 13.75px;
        letter-spacing: 0.5px;

        margin-left: 10px;
    }
`

export const IconButton = styled.button`
    border: none;
    background: none;
    width: max-content;
    padding: 0px;
`

export const ImageTitleContainer = styled.div`
    display: flex;
    width: 40%;
    gap: 10px;
    justify-content: center;

    @media (max-width: 460px) {
        width: 80%;
    }
`

export const PlayerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;

    @media (max-width: 460px) {
        align-items: center;
    }
`

export const SwitchContainer = styled.div`
    width: 150px;
    height: 35px;
    display: flex;
    margin: auto;

    background: #1f1f1f;
    color: #949494;
    border-radius: 100px;

    font-size: 14px;
    font-weight: 600;
    line-height: 13.75px;
    letter-spacing: 0.5px;
`

export const AudioButton = styled.button`
    border: none;
    width: 50%;
    height: 100%;
    background: ${(props) => (props.videoSelected ? 'inherit' : 'FAFAFA')};
    color: ${(props) => (props.videoSelected ? 'inherit' : 'black')};
    border-radius: 100px;
`
export const VideoButton = styled.button`
    border: none;
    width: 50%;
    height: 100%;
    background: ${(props) => (!props.videoSelected ? 'inherit' : 'FAFAFA')};
    color: ${(props) => (!props.videoSelected ? 'inherit' : 'black')};
    border-radius: 100px;
`

export const ReactPlayerContainer = styled.div`
    width: 75%;
    max-height: 75%;

    margin: auto;
    position: relative;

    @media (max-width: 990px) {
        max-height: 40%;
    }

    @media (max-width: 600px) {
        width: 100%;
    }

    @media (max-width: 990px) {
        left: ${(props) => props.showFullScreen && '0px'};
    }

    @media (min-width: 990px) {
        left: ${(props) => props.showFullScreen && '-225px'};
    }

    &:hover .video-control-container {
        visibility: visible;
    }
`

export const VideoOverlayOnHover = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #00000082;
    visibility: hidden;
`

export const VideoControlContainer = styled.div`
    display: flex;
    gap: 10px;
    position: absolute;
    right: 10px;
    bottom: 10px;
`

export const MobileVideoControlContainer = styled.div`
    @media (min-width: 990px) {
        display: none;
    }

    width: 100%;
    height: 100%;
    visibility: hidden;
    background: #00000045;

    position: absolute;
    top: 0px;
    left: 0px;

    .top-control {
        width: 96%;

        @media (max-width: 460px) {
            width: 90%;
        }

        display: flex;
        flex-direction: column;
        gap: 10px;

        position: absolute;
        top: 5%;
        left: 5%;
        transform: translate(-5%, -5%);

        padding-left: 20px;
    }

    .mid-control {
        width: 96%;

        @media (max-width: 460px) {
            width: 90%;
        }

        display: flex;
        justify-content: center;
        gap: 40px;

        position: absolute;
        bottom: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .bottom-control {
        width: 96%;

        @media (max-width: 460px) {
            width: 90%;
        }

        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: flex-end;

        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
    }
`
export const MobilePrevNext = styled.div`
    display: none;

    @media (max-width: 990px) {
        display: flex;
        gap: 10px;
    }
`

export const RadioFavorite = styled.div`
    display: flex;
    gap: 5px;

    img {
        width: 20px;
        height: 20px;
    }

    span {
        color: #b9b9b9;
        font-size: 12px;
        font-weight: 400;
        line-height: 19.2px;
        letter-spacing: 0.12px;
    }
`

export const DesktopOnly = styled.div`
    @media (max-width: 990px) {
        display: none;
    }
`

export const MobileOnly = styled.div`
    @media (min-width: 991px) {
        display: none;
    }
`

export const LikeCommentDesktopContainer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: center;

    div {
        display: flex;
        align-items: center;
        gap: 6px;
        width: max-content;
    }

    img {
        width: 20px;
        height: 20px;
        max-width: unset;
    }

    span {
        color: #b9b9b9;
        font-size: 12px;
        font-weight: 400;
        line-height: 19.2px;
        letter-spacing: 0.12px;
    }
`

export const LikeCommentMobileContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 30%;
        height: 28px;
        border-radius: 14px;
        background: rgba(255, 255, 255, 0.2);
    }

    img {
        width: 16px;
        height: 16px;
        max-width: unset;
    }

    span {
        color: #b9b9b9;
        font-size: 11px;
        font-weight: 600;
        line-height: 13.75px;
        letter-spacing: 0.12px;
    }
`

export const HoverState = styled.div`
    position: relative;
    &:hover::after {
        content: '';
        position: absolute;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-color: rgba(255, 255, 255, 0.1);
        width: 34px;
        height: 34px;
        border-radius: 100px;
    }
`

export const AdButtonOnMiniplayer = styled.a`
    border-radius: 14px;
    background: #463d06;

    color: #fad810;
    text-align: center;

    font-size: 14px;
    font-weight: 600;
    line-height: 17.5;
    letter-spacing: 0.1px;

    display: flex;
    height: 28px;
    padding: 5px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    @media (max-width: 990px) {
        display: none;
    }
`

export const AdButtonOnMobile = styled.a`
    display: flex;
    height: 52px;
    padding: 15px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    color: #fad810;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 14px;
    background: var(--vb-ref-color-primary-20, #463d06);
    margin-top: 20px;
`
